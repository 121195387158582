<template>
  <GoorooPassCardStateNotSubscribed :pricing="pricing" />
</template>

<script>
  import GoorooPassCardStateNotSubscribed from "./GoorooPassCardStateNotSubscribed";

  export default {
    name: "GoorooPassCardStateEnded",
    components: { GoorooPassCardStateNotSubscribed },
    props: {
      pricing: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style lang="scss">

</style>
