<template>
  <transition name="goo-modal-transition" appear>
    <goo-modal active>
      <div class="goo-dialog goo-form-dialog">
        <goo-close-button @click="close" />

        <CancelGoorooPassStep1
          v-if="step === 1"
          :effective-date="effectiveDate"
          @cancelled="onCancel"
          @keep="onKeep"
        />

        <CancelGoorooPassStep2
          v-else-if="step === 2"
          @close="close"
        />
      </div>
    </goo-modal>
  </transition>
</template>

<script>
  import { formatDate } from "@gooroo-life/goo-formatters";
  import CancelGoorooPassStep1 from "./CancelGoorooPassStep1";
  import CancelGoorooPassStep2 from "@/components/Settings/tabs/GoorooPass/CancelGoorooPassModal/CancelGoorooPassStep2";

  export default {
    name: "CancelGoorooPassModal",
    components: { CancelGoorooPassStep2, CancelGoorooPassStep1 },
    filters: {
      formatDate
    },
    props: {
      effectiveDate: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        step: 1
      };
    },
    methods: {
      close () {
        this.$emit("close");
      },
      onCancel () {
        this.step = 2;
      },
      onKeep () {
        this.close();
      }
    }
  };
</script>

<style lang="scss">

</style>
