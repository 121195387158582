<template>
  <form @submit.prevent="submit">
    <header class="goo-dialog-heading has-text-centered">
      <h3 class="heading-3">
        We appreciate your feedback
      </h3>
      <p>Your Gooroo Pass subscription has been cancelled successfully. Tell us how we can improve.</p>
    </header>

    <hr class="goo-divider">

    <div>
      <div v-for="(reason, key) in options" :key="key" class="mb-4">
        <div class="goo-radio is-primary">
          <input :id="key" v-model="selected" type="radio" name="cancellation_reason" :value="key" :disabled="saving">
          <label class="session-cancellation-label" :for="key">{{ reason }}</label>
        </div>
      </div>
      <div v-if="selected === 'OTHER'">
        <label for="cancellation_other" class="is-sr-only">Other</label>
        <textarea id="cancellation_other" v-model="other" :disabled="saving" class="goo-input is-small" placeholder="Why are you cancelling?" />
      </div>
    </div>

    <footer class="goo-button-group">
      <goo-submit loading-text="Sending..." :loading="saving" :disabled="disableSubmitButton" class="goo-button is-primary is-large is-full-width">
        Submit Feedback
      </goo-submit>
      <button type="button" class="goo-button is-large is-text is-full-width" @click="skip">
        Skip
      </button>
    </footer>
  </form>
</template>

<script>
  import { SUBMIT_GOOROO_PASS_CANCELLATION_FEEDBACK } from "./mutations";

  export default {
    name: "CancelGoorooPassStep2",
    data () {
      return {
        saving: false,
        selected: null,
        other: "",
        options: {
          CONTENT: "Not enough content that interests me.",
          ENJOYMENT: "Do not enjoy the online learning experience.",
          TIME: "Do not have enough time to take courses now.",
          VALUE: "Too expensive for how much I use it.",
          COST: "I can’t afford the monthly subscription.",
          OTHER: "Other"
        }
      };
    },
    computed: {
      feedback () {
        if (this.selected === "OTHER") {
          return this.other;
        }

        return this.options[this.selected] || "";
      },
      disableSubmitButton () {
        return this.feedback.trim() === "";
      }
    },
    methods: {
      submit () {
        this.saving = true;

        this.$apollo.mutate({
          mutation: SUBMIT_GOOROO_PASS_CANCELLATION_FEEDBACK,
          variables: {
            feedback: this.feedback
          }
        }).then(() => {
          this.$emit("close");
        }).finally(() => {
          this.saving = false;
        });
      },
      skip () {
        this.$emit("close");
      }
    }
  };
</script>

<style lang="scss">
</style>
