import gql from "graphql-tag";

export const CANCEL_GOOROO_PASS = gql`
  mutation CancelGoorooPassSubscription {
    cancelGoorooPassSubscription {
      subscription {
        id
        status
        endsAt
      }
    }
  }
`;

export const SUBMIT_GOOROO_PASS_CANCELLATION_FEEDBACK = gql`
  mutation SubmitGoorooPassCancellationFeedback($feedback: String!) {
    submitUserFeedback(feedback: $feedback, touchpoint: GOOROO_PASS_CANCELLATION) {
      feedback {
        id
      }
    }
  }
`;
