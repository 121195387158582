<template>
  <div class="gooroo-pass-card">
    <div class="gooroo-pass-card-header" :class="headerClass">
      <a href="https://courses.gooroo.com/" target="_blank" class="hide-small-only">
        Gooroo Courses <goo-icon name="chevron-right" />
      </a>

      <h3>Gooroo Pass</h3>
    </div>

    <div class="gooroo-pass-card-content">
      <div class="gooroo-pass-card-column">
        <p>
          Access thousands of quality courses across dozens of subjects.
          Learn anytime, anywhere for {{ pricing.price | formatCurrency }} per month.
        </p>

        <p v-if="subscription" class="is-marginless">
          <strong class="has-text-bold">Status:</strong> {{ status }}
        </p>
      </div>

      <component
        :is="stateComponent"
        :pricing="pricing"
        :next-bill="nextBill"
        :subscription="subscription"
      />
    </div>
  </div>
</template>

<script>
  import { formatDate, formatCurrency } from "@gooroo-life/goo-formatters";

  import { GET_PAYMENT_METHOD } from "@/components/queries";
  import GooUpdatePaymentMethod from "@/goo2/applets/goo-update-payment-method/GooUpdatePaymentMethod";

  import GoorooPassCardStateEnded from "./states/GoorooPassCardStateEnded";
  import GoorooPassCardStateActive from "./states/GoorooPassCardStateActive";
  import GoorooPassCardStatePastDue from "./states/GoorooPassCardStatePastDue";
  import GoorooPassCardStateCanceled from "./states/GoorooPassCardStateCanceled";
  import GoorooPassCardStateNotSubscribed from "./states/GoorooPassCardStateNotSubscribed";

  const stateComponents = {
    ENDED: GoorooPassCardStateEnded,
    ACTIVE: GoorooPassCardStateActive,
    TRIALING: GoorooPassCardStateActive,
    PAST_DUE: GoorooPassCardStatePastDue,
    CANCELED: GoorooPassCardStateCanceled
  };

  export default {
    name: "GoorooPassCard",
    components: {
      GooUpdatePaymentMethod,
      GoorooPassCardStateEnded,
      GoorooPassCardStateActive,
      GoorooPassCardStatePastDue,
      GoorooPassCardStateCanceled,
      GoorooPassCardStateNotSubscribed
    },
    filters: {
      formatCurrency,
      formatDate
    },
    props: {
      pricing: {
        type: Object,
        required: true
      },
      subscription: {
        type: Object,
        default: null
      },
      nextBill: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        paymentMethod: null,
        sellingPoints: [
          "New content added weekly",
          "Connect with top instructors",
          "Cancel anytime"
        ]
      };
    },
    apollo: {
      paymentMethod: GET_PAYMENT_METHOD
    },
    computed: {
      headerClass () {
        if (this.subscription == null) { return null; }

        return {
          "is-dark": ["ENDED", "CANCELED"].includes(this.subscription.status)
        };
      },
      status () {
        switch (this.subscription.status) {
        case "ACTIVE":
          if (this.subscription.endsAt) {
            return `Active (Ends on ${formatDate(this.subscription.endsAt)})`;
          }
          return "Active";
        case "TRIALING":
          return `Trial until ${formatDate(this.subscription.trialEndsAt)}`;
        case "CANCELED":
          return `Cancelled (Ending on ${formatDate(this.subscription.endsAt)})`;
        case "PAST_DUE":
          return "Past due";
        case "ENDED":
          return `Ended on ${formatDate(this.subscription.endsAt)}`;
        default:
          return this.subscription.status;
        }
      },
      stateComponent () {
        if (this.subscription === null) {
          return GoorooPassCardStateNotSubscribed;
        }

        return stateComponents[this.subscription.status];
      }
    }
  };
</script>

<style lang="scss">
  @import "@/css/theme";

  $compact-threshold: 1000px;

  .gooroo-pass-card {
    background: #ffffff;
    max-width: 780px;
    border: 1px solid #ebebeb;
    margin: 0 0 16px;

    &-header {
      color: #fff;
      position: relative;
      background: #00bfc2;
      padding: 32px 24px;

      @media only screen and (min-width: $compact-threshold) {
        padding: 64px 42px;
      }

      &.is-dark {
        background: #3a3a3a;

        a:hover {
          background: rgba(#fff, 0.1);
        }
      }

      a {
        color: #ffffff;
        position: absolute;
        padding: 8px 8px 8px 16px;
        border-radius: 4px;
        top: 8px;
        right: 8px;

        &:hover {
          background: rgb(#000, 0.05);
        }
      }

      h3 {
        color: #ffffff;
        font-family: $family-serif;
        font-size: $size-1;

        @media only screen and (min-width: $compact-threshold) {
          font-size: $size-0;
        }
      }
    }

    &-content {
      box-sizing: border-box;
      padding: 16px;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr;

      @media only screen and (min-width: $compact-threshold) {
        grid-template-columns: repeat(2, 1fr);
        padding: 32px;
        min-height: 220px;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

    }

    &:first-child {
      border-bottom: 1px solid #f0f0f0;
      padding: 0 0 16px;

      @media only screen and (min-width: $compact-threshold) {
        border: none;
        padding: 0;
      }
    }
    .payment-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &-details {
        flex: 1;
        margin: 0 8px;
      }
    }
  }
</style>
