<template>
  <p class="credit-card-declined-error is-error-text-color">
    <goo-icon name="circle-error" />
    <span><slot /></span>
  </p>
</template>

<script>
  export default {
    name: "CreditCardDeclinedError"
  };
</script>

<style lang="scss">
  .credit-card-declined-error {
    display: flex;
    flex-direction: row;
    margin: 0 0 16px;

    .goo-icon {
      flex-shrink: 0;
      margin: 3px 8px 0 0;
    }
  }
</style>
