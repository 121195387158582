<template>
  <div>
    <header class="goo-dialog-heading has-text-centered">
      <h3 class="heading-3">
        Sorry to see you go.
      </h3>
      <p>Here is what you should know before you cancel.</p>
    </header>

    <hr class="goo-divider">

    <ul class="goo-checklist">
      <li class="goo-checklist-item">
        <goo-icon name="cross-circle-outline" class="is-error" />
        You will lose access to all premium courses starting {{ effectiveDate | formatDate("long") }}.
      </li>
      <li class="goo-checklist-item">
        <goo-icon name="cross-circle-outline" class="is-error" />
        All premium future courses added daily will be unavailable to you.
      </li>
      <li class="goo-checklist-item">
        <goo-icon name="circle-check-outline" class="is-primary" />
        Reactivate your subscription anytime!
      </li>
    </ul>

    <GooFlash v-if="error" type="error">
      {{ error }}
    </GooFlash>

    <footer class="goo-button-group">
      <button class="goo-button is-primary is-large is-full-width" :disabled="disableButtons" @click="keep">
        Keep Subscription
      </button>
      <goo-submit class="goo-button is-primary is-outlined is-large is-full-width" :disabled="disableButtons" :loading="cancelling" @click="cancel">
        Confirm Cancellation
      </goo-submit>
    </footer>
  </div>
</template>

<script>
  import { formatDate } from "@gooroo-life/goo-formatters";
  import { CANCEL_GOOROO_PASS } from "./mutations";
  import GooFlash from "@/goo2/components/goo-flash/GooFlash";

  export default {
    name: "CancelGoorooPassStep1",
    components: { GooFlash },
    filters: {
      formatDate
    },
    props: {
      effectiveDate: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        cancelling: false,
        error: null,
        disableButtons: true
      };
    },
    mounted () {
      window.setTimeout(() => {
        this.disableButtons = false;
      }, 1000);
    },
    methods: {
      keep () {
        this.$emit("keep");
      },
      cancel () {
        this.cancelling = true;

        this.$apollo.mutate({
          mutation: CANCEL_GOOROO_PASS
        }).catch((error) => {
          this.error = error.message || "An error has occurred, please try again.";
        }).then(() => {
          this.$emit("cancelled");
        }).finally(() => {
          this.cancelling = false;
        });
      }
    }
  };
</script>

<style lang="scss">

</style>
