<template>
  <div>
    <GooSkeleton v-if="isLoading" class="heading-3" height="36" width="160" />
    <h1 v-else-if="subscription" class="heading-3">
      My Plan
    </h1>
    <h1 v-else class="heading-3">
      Available Plan
    </h1>

    <GooSkeleton v-if="isLoading" width="100%" height="434" />
    <template v-else>
      <GoorooPassCard :pricing="pricing" :subscription="subscription" :next-bill="nextBill" />

      <p v-if="subscription && subscription.canBeCanceled">
        <a href="#" class="goo-link" @click.prevent="cancelling = true">
          Cancel my Gooroo Pass subscription
        </a>
      </p>

      <CancelGoorooPassModal v-if="cancelling" :effective-date="nextBill.date" @close="cancelling = false" />
    </template>
  </div>
</template>

<script>
  import { GET_GOOROO_PASS_DATA } from "./queries";

  import GoorooPassCard from "./GoorooPassCard/GoorooPassCard";
  import CancelGoorooPassModal from "./CancelGoorooPassModal/CancelGoorooPassModal";

  export default {
    name: "GoorooPassScreen",
    components: { GoorooPassCard, CancelGoorooPassModal },
    data () {
      return {
        pricing: null,
        nextBill: null,
        subscription: null,
        cancelling: false
      };
    },
    computed: {
      isLoading () {
        return (
          this.pricing == null &&
          (
            this.$apollo.queries.pricing.loading ||
            this.$apollo.queries.subscription.loading
          )
        );
      }
    },
    apollo: {
      pricing: GET_GOOROO_PASS_DATA,
      nextBill: GET_GOOROO_PASS_DATA,
      subscription: GET_GOOROO_PASS_DATA
    }
  };
</script>

<style lang="scss">

</style>
