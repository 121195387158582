import gql from "graphql-tag";

export const GET_GOOROO_PASS_DATA = gql`
  query GetGoorooPassData {
    subscription: passSubscription {
      id
      provider
      status
      trialEndsAt
      endsAt
      onTrial
      isActive
      canBeCanceled
    }
    pricing: passPricing(platform: WEB) {
      price
      trialPeriodDays
      isEligibleForTrial
    }
    nextBill: passNextBill {
      amount
      date
    }
  }
`;
