<template>
  <div class="gooroo-pass-card-column">
    <div>
      <h3 class="heading-3 is-marginless">
        Payment
      </h3>

      <p>
        Your next bill is for <strong class="has-text-bold">{{ nextBill.amount | formatCurrency }}</strong>,
        on <strong class="has-text-bold">{{ nextBill.date | formatDate }}</strong>.
      </p>

      <slot />
    </div>

    <div>
      <GooSkeleton v-if="isLoadingPaymentMethod" width="100%" height="48" />
      <div v-else class="payment-info">
        <PaymentMethod :payment-method="paymentMethod" />
        <div>
          <ResponsiveEditButton @click="updatingPaymentMethod = true">
            Update
          </ResponsiveEditButton>
        </div>
      </div>

      <GooUpdatePaymentMethod
        v-if="updatingPaymentMethod"
        has-payment-method
        @cancel="updatingPaymentMethod = false"
        @updated="onPaymentMethodUpdated"
      />
    </div>
  </div>
</template>

<script>
  import { formatDate, formatCurrency } from "@gooroo-life/goo-formatters";

  import { GET_PAYMENT_METHOD } from "@/components/queries";
  import PaymentMethod from "@/components/Settings/components/PaymentMethod";
  import GooUpdatePaymentMethod from "@/goo2/applets/goo-update-payment-method/GooUpdatePaymentMethod";
  import ResponsiveEditButton from "@/components/Settings/components/ResponsiveEditButton";

  export default {
    name: "GoorooPassCardStateActive",
    components: { ResponsiveEditButton, GooUpdatePaymentMethod, PaymentMethod },
    filters: {
      formatDate,
      formatCurrency
    },
    props: {
      nextBill: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        paymentMethod: null,
        updatingPaymentMethod: false
      };
    },
    apollo: {
      paymentMethod: GET_PAYMENT_METHOD
    },
    computed: {
      isLoadingPaymentMethod () {
        return this.$apollo.queries.paymentMethod.loading;
      }
    },
    methods: {
      onPaymentMethodUpdated () {
        this.updatingPaymentMethod = false;
        this.$apollo.queries.paymentMethod.refresh();
      }
    }
  };
</script>

<style lang="scss">

</style>
