<template>
  <div class="gooroo-pass-card-column">
    <div>
      <h3 class="heading-3 is-marginless">
        Keep Learning!
      </h3>

      <p class="is-marginless">
        You still have access to all the premium courses offered by
        Gooroo Pass until <strong>{{ subscription.endsAt | formatDate }}</strong>.
        After that, you will have the option to sign up for Gooroo Pass again.
      </p>
    </div>
  </div>
</template>

<script>
  import { formatDate } from "@gooroo-life/goo-formatters";

  export default {
    name: "GoorooPassCardStateCanceled",
    filters: {
      formatDate
    },
    props: {
      subscription: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style lang="scss">

</style>
