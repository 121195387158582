<template>
  <div class="gooroo-pass-card-column">
    <ul>
      <li v-for="point in sellingPoints" :key="point">
        <goo-icon name="circle-check-filled" class="is-primary" /> {{ point }}
      </li>
    </ul>
    <p class="is-marginless">
      <router-link :to="{name: 'gooroo-pass.checkout'}" class="goo-button is-secondary">
        {{ cta }}
      </router-link>
    </p>
  </div>
</template>

<script>
  export default {
    name: "GoorooPassCardStateNotSubscribed",
    props: {
      pricing: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        sellingPoints: [
          "New content added weekly",
          "Connect with top instructors",
          "Cancel anytime"
        ]
      };
    },
    computed: {
      cta () {
        if (!this.pricing.isEligibleForTrial) {
          return "Get Gooroo Pass";
        }

        return `Start ${this.pricing.trialPeriodDays} day trial`;
      }
    }
  };
</script>

<style lang="scss">

</style>
