<template>
  <GoorooPassCardStateActive :next-bill="nextBill">
    <CreditCardDeclinedError>Your card was declined. Please try another card or contact your bank.</CreditCardDeclinedError>
  </GoorooPassCardStateActive>
</template>

<script>
  import GoorooPassCardStateActive from "./GoorooPassCardStateActive";
  import CreditCardDeclinedError from "../CreditCardDeclinedError";

  export default {
    name: "GoorooPassCardStatePastDue",
    components: { CreditCardDeclinedError, GoorooPassCardStateActive },
    props: {
      nextBill: {
        type: Object,
        required: true
      }
    }
  };
</script>

<style lang="scss">

</style>
